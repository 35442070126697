<template>
  <StepWrapper>
    <template #prepend>
      <BackButton @click="$emit('back')" />
    </template>
    <template #title>
      {{ t('merchantWallet.generate.familySelection.title') }}
    </template>
    <template #description>
      {{ t('merchantWallet.generate.familySelection.description') }}
    </template>

    <template #extra>
      <AboutImportWalletsLink>
        {{ t('merchantWallet.learnMore') }}
      </AboutImportWalletsLink>
    </template>

    <FamilySelection v-model="bitcoin">
      <template #title>
        BIP39
      </template>
      <template #description>
        {{ t('merchantWallet.generate.familySelection.bip39') }}
      </template>
      <template v-for="coin in bip39FamilySelection" :key="coin">
        <CoinBlock :value="coin" />
      </template>
      <template #extra>
        <AppText
          size="13px"
          class="font-medium"
          variant="div"
          mt="24px"
          mb="12px"
        >
          {{ t('merchantWallet.generate.familySelection.bip39thirdParty') }}
        </AppText>
        <ExtraCoins />
      </template>
    </FamilySelection>

    <FamilySelection v-model="monero">
      <template #title>
        Monero
      </template>
      <template #description>
        {{ t('merchantWallet.generate.familySelection.monero') }}
      </template>
      <CoinBlock value="XMR" />
    </FamilySelection>

    <div class="d-flex align-items-center justify-content-between">
      <FButton :disabled="!monero && !bitcoin" @click="setCoins">
        {{ t('common.continue') }}
      </FButton>
      <ResetButton />
    </div>
  </StepWrapper>
</template>

<script setup>
import { ref } from 'vue';

import AboutImportWalletsLink from '@/views/merchant/Wallet/WalletSetup/WalletImport/ImportKeys/AboutImportWalletsLink.vue';

import ExtraCoins from '../components/ExtraCoins.vue';
import BackButton from '../components/BackButton.vue';
import FamilySelection from '../components/FamilySelection.vue';
import CoinBlock from '../components/CoinBlock.vue';
import ResetButton from '../components/ResetButton.vue';
import StepWrapper from '../components/StepWrapper.vue';
import { bip39FamilySelection, useWalletSetup } from '../useWalletSetup';

const emit = defineEmits(['next', 'back']);

const bitcoin = ref(true);
const monero = ref(false);

const { selectedCoinsFamily } = useWalletSetup();

const setCoins = () => {
  selectedCoinsFamily.value = [];
  if (bitcoin.value) {
    selectedCoinsFamily.value.push('bip39Family');
  }
  if (monero.value) {
    selectedCoinsFamily.value.push('moneroFamily');
  }
  emit('next');
};
</script>

<style scoped lang="scss">
:deep(.el-switch.is-disabled) {
  opacity: 1 !important;
}
</style>
