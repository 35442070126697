<template>
  <StepWrapper>
    <template #prepend>
      <BackButton @click="$emit('back')" />
    </template>
    <template #title>
      {{ t('merchantWallet.generate.verification.title') }}
    </template>
    <template #description>
      {{ t('merchantWallet.generate.verification.description') }}
    </template>

    <VerificationItem
      v-if="selectedCoinsFamily.includes('bip39Family')"
      ref="bip39Container"
      :mnemonic="bip39Mnemonic"
    >
      <template #title>
        {{ t('merchantWallet.generate.seed.bip39') }}
      </template>
    </VerificationItem>
    <VerificationItem
      v-if="selectedCoinsFamily.includes('moneroFamily')"
      ref="moneroContainer"
      :mnemonic="moneroMnemonic"
      is-auto-focus-disabled
    >
      <template #title>
        {{ t('merchantWallet.generate.seed.monero') }}
      </template>
    </VerificationItem>

    <div style="height: 20px; margin-bottom: 16px;">
      <AppText v-if="mnemonicHasError" color="var(--color-error)" size="14px">
        {{ t('merchantWallet.generate.verification.wrong') }}
      </AppText>
    </div>

    <div class="d-flex align-items-center justify-content-between" style="max-width: 580px;">
      <FButton :loading="isLoading" @click="onValidate">
        {{ t('common.continue') }}
      </FButton>
      <ResetButton />
    </div>
  </StepWrapper>
</template>

<script setup>
import { ref } from 'vue';

import BackButton from '../components/BackButton.vue';
import ResetButton from '../components/ResetButton.vue';
import StepWrapper from '../components/StepWrapper.vue';
import VerificationItem from '../components/VerificationItem.vue';
import { useWalletSetup } from '../useWalletSetup';

const emit = defineEmits(['back', 'validate']);

const {
  bip39Mnemonic,
  moneroMnemonic,
  selectedCoinsFamily,
} = useWalletSetup();

const bip39Container = ref(null);
const moneroContainer = ref(null);

const mnemonicHasError = ref(false);
const isLoading = ref(false);

const onValidate = () => {
  isLoading.value = true;

  let isValid = false;
  if (selectedCoinsFamily.value.includes('bip39Family')) {
    isValid = bip39Container.value.onValidate();
  }
  if (selectedCoinsFamily.value.includes('moneroFamily')) {
    isValid = moneroContainer.value.onValidate();
  }
  mnemonicHasError.value = false;

  if (!isValid) {
    mnemonicHasError.value = true;
    isLoading.value = false;
    return;
  }
  emit('validate');
};
</script>
