<template>
  <div class="item" tabindex="0">
    <div class="flex-grow-1">
      <div class="d-flex align-items-center" style="gap: 16px; margin-bottom: 10px;">
        <AppIcon :name="icon" size="24px" is-img-tag />
        <AppText size="15px" class="font-medium">
          {{ title }}
        </AppText>
      </div>
      <AppText :line-height="1.5" :opacity="0.6">
        {{ description }}
      </AppText>
    </div>
    <div class="action-icon-wrap">
      <AppIcon name="arrow-bracket" class="rotate-90" size="18px" is-img-tag />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetupStartItem',
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  max-width: 640px;
  cursor: pointer;
  background-color: var(--color-F7F7F7);
  border-radius: 12px;
  padding: 30px;
  display: flex;
  align-items: center;
  gap: 64px;
  @include transition-base('background-color');
  &:focus-visible {
    @include focus-visible-style;
    border-radius: 12px !important;
  }

  &:hover {
    background-color: var(--color-FBF3EC);
  }

  .action-icon-wrap {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    @include flex-center;
    background-color: var(--color-black-005);
    border-radius: 50%;
  }
}
</style>
