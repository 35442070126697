<template>
  <StepWrapper width="400px">
    <template #prepend>
      <div class="done-icon">
        <AppIcon name="arrow-check-done" size="18px" fill="#ffffff" />
      </div>
    </template>
    <template #title>
      {{ t('merchantWallet.generate.final.title') }}
    </template>
    <template #description>
      <slot />
    </template>

    <template #extra>
      <ExternalLink style="margin-top: 28px;" :href="link">
        <template #prepend>
          <AppIcon name="info-circle-big" size="18px" :opacity="0.4" is-img-tag />
        </template>
        {{ t('merchantWallet.generate.final.learnMore') }}
      </ExternalLink>
    </template>

    <FButton :loading="isLoading" @click="onFinish">
      {{ t('merchantWallet.generate.final.finish') }}
    </FButton>
  </StepWrapper>
</template>

<script setup>
import { ref } from 'vue';

import ExternalLink from '@/components/ExternalLink.vue';

import StepWrapper from '../components/StepWrapper.vue';

const emit = defineEmits(['finish']);

const isLoading = ref(false);
const onFinish = () => {
  isLoading.value = true;
  emit('finish');
};

const link = `https://${process.env.VUE_APP_DOCS_URL}/home/merchant-guide/`;
</script>

<style scoped lang="scss">
.done-icon {
  @include flex-center;
  padding: 7px 17px;
  background: var(--color-green);
  border-radius: 30px;
  width: 52px;
}
</style>
