<template>
  <StepWrapper>
    <template #prepend>
      <BackButton @click="$emit('back')" />
    </template>
    <template #title>
      {{ t('merchantWallet.generate.password.title') }}
    </template>
    <template #description>
      {{ t('merchantWallet.generate.password.description') }}
    </template>

    <div class="d-flex flex-column" style="gap: 20px;">
      <DropdownBlock
        class="skip-pass"
        type="plain"
        no-content
        @click="skipPasswordStep"
      >
        <template #header>
          <AppText
            variant="div"
            size="17px"
            class="font-medium"
          >
            {{ t('merchantWallet.generate.password.continueWithout') }}
          </AppText>
        </template>
      </DropdownBlock>

      <DropdownBlock type="plain" height="282px">
        <template #header>
          <AppText
            variant="div"
            size="17px"
            class="font-medium"
          >
            {{ t('merchantWallet.generate.password.setup') }}
          </AppText>
        </template>
        <template #default>
          <div class="password-wrapper">
            <AppText variant="div" mb="20px">
              {{ t('merchantWallet.generate.password.setupDescription') }}
            </AppText>

            <FInput
              v-model="password"
              :type="isPasswordVisible ? 'text' : 'password'"
              :validation-error="errorMessage.password"
              :placeholder="t('merchantWallet.generate.password.passphrase')"
              class="password-input"
              @keypress.enter.stop="setPassword"
            >
              <template #append>
                <FButton
                  icon="eye-view"
                  is-icon
                  type="transparent"
                  @click="isPasswordVisible = !isPasswordVisible"
                />
              </template>
            </FInput>

            <FInput
              v-model="passwordConfirm"
              :type="isConfirmVisible ? 'text' : 'password'"
              :validation-error="errorMessage.confirm"
              :placeholder="t('merchantWallet.generate.password.confirm')"
              class="password-input"
              @keypress.enter.stop="setPassword"
            >
              <template #append>
                <FButton
                  icon="eye-view"
                  is-icon
                  type="transparent"
                  @click="isConfirmVisible = !isConfirmVisible"
                />
              </template>
            </FInput>
            <FButton @click="setPassword">
              {{ t('merchantWallet.generate.password.button.set') }}
            </FButton>
          </div>
        </template>
      </DropdownBlock>
    </div>
    <div class="d-flex justify-content-end">
      <ResetButton mt="40px" is-block />
    </div>
  </StepWrapper>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';

import DropdownBlock from '@/components/Containers/DropdownBlock.vue';

import { i18n } from '@/plugins/localization';

import BackButton from '../components/BackButton.vue';
import ResetButton from '../components/ResetButton.vue';
import StepWrapper from '../components/StepWrapper.vue';
import { useWalletSetup } from '../useWalletSetup';

export default defineComponent({
  components: {
    StepWrapper,
    BackButton,
    DropdownBlock,
    ResetButton,
  },
  emits: ['next', 'back'],
  setup(props, { emit }) {
    const { password } = useWalletSetup();
    const passwordConfirm = ref('');

    const isPasswordVisible = ref(false);
    const isConfirmVisible = ref(false);

    const errorMessage = ref({
      password: { message: '' },
      confirm: { message: '' },
    });

    const { t } = i18n.global;
    const setPassword = () => {
      if (!password.value) {
        errorMessage.value.password.message = t('merchantWallet.generate.password.validation.notEmpty');
        return;
      }
      if (password.value.length < 10) {
        errorMessage.value.password.message = t('merchantWallet.generate.password.validation.min', { min: 10 });
        return;
      }
      if (password.value.length > 30) {
        errorMessage.value.password.message = t('merchantWallet.generate.password.validation.max', { max: 30 });
        return;
      }
      if (password.value !== passwordConfirm.value) {
        errorMessage.value.confirm.message = t('merchantWallet.generate.password.validation.match');
        return;
      }
      errorMessage.value.password.message = '';
      errorMessage.value.confirm.message = '';

      emit('next');
    };

    const skipPasswordStep = () => {
      password.value = '';
      emit('next');
    };

    watch(password, () => { errorMessage.value.password.message = ''; });
    watch(passwordConfirm, () => { errorMessage.value.confirm.message = ''; });

    return {
      password,
      passwordConfirm,

      isPasswordVisible,
      isConfirmVisible,

      errorMessage,
      setPassword,
      skipPasswordStep,
    };
  },
});
</script>

<style scoped lang="scss">
.password-wrapper {
  padding: 16px 30px 40px;
  background: #F7F7F7;
  border-radius: 6px;

  :deep(.el-button .icon) {
    opacity: 0.4;
  }
}

.password-input {
  margin-bottom: 20px;
}

.password-input:deep(.el-input__inner) {
  padding-right: 50px !important;
  height: 48px;
}

.password-input:deep(.has-value .el-input__inner[type="password"]) {
  font-size: 20px !important;
  &::placeholder {
    font-size: 14px !important;
  }
}

.skip-pass {
  :deep(.arrow-inactive) {
    transform: rotate(90deg) !important;
  }
}
</style>
