<template>
  <div class="setup-start">
    <SetupStartItem
      icon="install-wallet"
      :title="t('merchantWallet.generate.start.title')"
      :description="t('merchantWallet.generate.start.description')"
      @click="$emit('start-generation-setup')"
      @keypress.space="$emit('start-generation-setup')"
    />

    <SetupStartItem
      icon="key"
      :title="t('merchantWallet.import.start.title')"
      :description="t('merchantWallet.import.start.description')"
      @click="$emit('start-import-setup')"
      @keypress.space="$emit('start-import-setup')"
    />

    <div class="d-flex align-items-center justify-content-end" style="height: 44px;">
      <ResetButton />
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';

import { useTemporaryStore } from '@/store';
import ResetButton from '@/views/merchant/Wallet/WalletSetup/components/ResetButton.vue';

import SetupStartItem from './SetupStartItem.vue';

defineEmits(['start-generation-setup', 'start-import-setup']);

const { store } = useTemporaryStore();

onBeforeMount(() => {
  store.$patch({ importWallets: [] });
});
</script>

<style lang="scss" scoped>
.setup-start {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
