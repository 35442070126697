<template>
  <StepWrapper>
    <template #prepend>
      <AppText
        type="uppercase"
        size="16px"
        color="var(--color-error)"
        class="font-semibold"
      >
        {{ t('merchantWallet.generate.seed.important') }}
      </AppText>
    </template>
    <template #title>
      {{ t('merchantWallet.generate.seed.title') }}
    </template>
    <template #description>
      {{ t('merchantWallet.generate.seed.description') }}
    </template>

    <SeedPhrase v-if="selectedCoinsFamily.includes('bip39Family')" :coins="bip39Family">
      <template #title>
        {{ t('merchantWallet.generate.seed.bip39') }}
      </template>
      {{ bip39Mnemonic }}
    </SeedPhrase>
    <SeedPhrase v-if="selectedCoinsFamily.includes('moneroFamily')" :coins="['xmr']">
      <template #title>
        {{ t('merchantWallet.generate.seed.monero') }}
      </template>
      {{ moneroMnemonic }}
    </SeedPhrase>

    <div
      class="confirmation"
      :class="{ 'is-confirm-error': hasError }"
      @mouseup="isConfirmed = !isConfirmed"
      @click.stop
    >
      <FCheckbox
        :model-value="isConfirmed"
        style="margin-right: 16px;"
      />
      <AppText color="var(--color-error)" size="13px" :line-height="1.5">
        {{ t('merchantWallet.generate.seed.confirm') }}
      </AppText>
    </div>

    <div class="d-flex align-items-center justify-content-between">
      <FButton @click="onNext">
        {{ t('common.continue') }}
      </FButton>
      <ResetButton />
    </div>
  </StepWrapper>
</template>

<script>
import { defineComponent, ref } from 'vue';

import ResetButton from '../components/ResetButton.vue';
import SeedPhrase from '../components/SeedPhrase.vue';
import StepWrapper from '../components/StepWrapper.vue';
import { bip39Family, useWalletSetup } from '../useWalletSetup';

export default defineComponent({
  components: {
    StepWrapper,
    SeedPhrase,
    ResetButton,
  },
  emits: ['next', 'back'],
  setup(props, { emit }) {
    const {
      bip39Mnemonic,
      moneroMnemonic,
      selectedCoinsFamily,
      generateData,
    } = useWalletSetup();

    const isConfirmed = ref(false);
    const hasError = ref(false);

    generateData();

    const onNext = () => {
      if (isConfirmed.value) {
        emit('next');
      } else {
        hasError.value = true;
        setTimeout(() => {
          hasError.value = false;
        }, 150);
      }
    };

    return {
      isConfirmed,
      bip39Mnemonic,
      bip39Family,
      moneroMnemonic,
      selectedCoinsFamily,

      hasError,

      onNext,
    };
  },
});
</script>

<style scoped lang="scss">
.confirmation {
  background: rgb(194, 85, 85, 0.1);
  padding: 20px 30px 20px 20px;
  margin-bottom: 30px;
  cursor: pointer;
  border-radius: 2px;

  display: flex;
  align-items: center;
  @include transition-base(background);

  &.is-confirm-error {
    background: rgb(194, 85, 85, 0.2);
  }
}
</style>
